import styled from "styled-components";
import { useState, useEffect, Dispatch, SetStateAction } from "react";

const ANIMATION_TIME = 16000;

export const EvolvingEgg = ({
  evolving,
  setEvolving,
  setNFTVisible,
}: {
  evolving: boolean;
  setEvolving: Dispatch<SetStateAction<boolean>>;
  setNFTVisible: () => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (evolving) {
      setTimeout(() => {
        setExpanded(true);
        setNFTVisible();
      }, ANIMATION_TIME - 2800);
      setTimeout(() => setEvolving(false), ANIMATION_TIME);
      setTimeout(() => setHidden(true), ANIMATION_TIME + 2000);
    }
  }, [evolving, setEvolving, setNFTVisible]);

  return (
    <WhiteCircle evolving={evolving} expanded={expanded} hidden={hidden}>
      {evolving && (
        <>
          <Ray index={0} />
          <Ray index={1} />
          <Ray index={2} />
          <Ray index={3} />
          <Ray index={4} />
          <Ray index={5} />
          <Ray index={6} />
        </>
      )}
    </WhiteCircle>
  );
};

const WhiteCircle = styled.div<{ evolving: boolean; expanded: boolean; hidden: boolean }>`
  animation: ${({ evolving, expanded }) =>
    evolving && !expanded ? `breathe2 ${ANIMATION_TIME / 1000 - 4.7}s ease-in-out 2s` : "none"};
  border-radius: 50%;
  background: white;
  height: ${({ evolving, expanded }) => (expanded ? "3000px" : evolving ? "50px" : "400px")};
  position: absolute;
  transition: all 2s ease-in-out;
  opacity: ${({ evolving }) => (evolving ? 1 : 0)};
  overflow: visible;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  width: ${({ evolving, expanded }) => (expanded ? "3000px" : evolving ? "50px" : "400px")};
  z-index: 1000;

  @keyframes breathe2 {
    0% {
      height: 50px;
      width: 50px;
    }

    15% {
      height: 65px;
      width: 65px;
    }

    30% {
      height: 50px;
      width: 50px;
    }

    45% {
      height: 65px;
      width: 65px;
    }

    60% {
      height: 65px;
      width: 65px;
    }

    75% {
      height: 65px;
      width: 65px;
    }

    90% {
      height: 50px;
      width: 50px;
    }

    100% {
      height: 2000px;
      width: 2000px;
    }
  }
`;

const Ray = styled.div<{ index: number }>`
  animation: rotate ${ANIMATION_TIME / 1000 - 0.5}s linear ${({ index }) => 2 + index * 0.1}s;
  background: white;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 2px;

  @keyframes rotate {
    0% {
      height: 0px;
      transform: translate(-50%, -50%) rotate(${({ index }) => 90 * index}deg);
    }

    10% {
      height: 0px;
      transform: translate(-50%, -50%) rotate(${({ index }) => 100 + 90 * index}deg);
    }

    20% {
      height: 2000px;
      transform: translate(-50%, -50%) rotate(${({ index }) => 400 + 90 * index}deg);
    }

    30% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 900 + 90 * index}deg);
    }

    40% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 1600 + 90 * index}deg);
    }

    50% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 2500 + 90 * index}deg);
    }

    60% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 3600 + 90 * index}deg);
    }

    70% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 4900 + 90 * index}deg);
    }

    80% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 6400 + 90 * index}deg);
    }

    90% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 8100 + 90 * index}deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(${({ index }) => 10000 + 90 * index}deg);
    }
  }
`;
