import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import moment from "moment";
import ErrorWindow from "components/ErrorWindow";
import { ethers } from "ethers";
import styled from "styled-components";
import { ClaimButton } from "../ClaimNFT";
import { Web3Provider } from "@ethersproject/providers";
import { useNotifications } from "contexts/Notifications";
import egg1 from "eggs/egg-1.png";
import egg2 from "eggs/egg-2.png";
import egg3 from "eggs/egg-3.png";
import egg4 from "eggs/egg-4.png";
import egg5 from "eggs/egg-5.png";
import egg6 from "eggs/egg-6.png";
import egg7 from "eggs/egg-7.png";
import egg8 from "eggs/egg-8.png";
import egg9 from "eggs/egg-9.png";
import egg10 from "eggs/egg-10.png";
import { useCountdown } from "../../../hooks/useCountdown";
import { EvolvingEgg } from "./EvolvingEgg";
import { MintInfo } from "./MintInfo";
import { mintFrog } from "../utils/mintFrog";
import { FoundingFroggerNFT, FrogData } from "./FoundingFroggerNFT";

const REVEAL_DELAY = 500;
const REVEAL_TIME = 2000;
const ANIMATION_TIME = 3000;

const LAUNCH_TIME = moment.utc("2022-06-15T12:00:00").unix() * 1000; // 12pm UTC Weds 15th June 2022

const EGGS = [egg1, egg2, egg3, egg4, egg5, egg6, egg7, egg8, egg9, egg10];

const Frog = ({
  provider,
  account,
  frogId,
  setFrogId,
}: {
  provider: Web3Provider;
  account: string;
  frogId: number | null;
  setFrogId: Dispatch<SetStateAction<number>>;
}) => {
  const [visible, setVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [evolving, setEvolving] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [mintChecksPassed, setMintChecksPassed] = useState(false);
  const [beneficiary, setBeneficiary] = useState("");
  const [beneficiaryError, setBeneficiaryError] = useState(false);

  const {
    notification: { message },
    updateNotification,
  } = useNotifications();

  const { secondsLeft, minutesLeft, hoursLeft, daysLeft, countdownOver } =
    useCountdown(LAUNCH_TIME);

  useEffect(() => {
    setTimeout(() => setVisible(true), REVEAL_DELAY);
    setTimeout(() => setMessageVisible(true), REVEAL_DELAY + REVEAL_TIME);
  }, []);

  useEffect(() => {
    if (beneficiary && !ethers.utils.isAddress(beneficiary)) setBeneficiaryError(true);
    if (ethers.utils.isAddress(beneficiary)) setBeneficiaryError(false);
  }, [beneficiary]);

  const onMintClick = async () => {
    try {
      if (!mintChecksPassed) return setInfoModalOpen(true);
      if (!ethers.utils.isAddress(beneficiary)) return setBeneficiaryError(true);
      await mintFrog(account, provider, setFrogId, beneficiary);
      console.log("MINT COMPLETE");

      setMessageVisible(false);
      setTimeout(() => {
        setVisible(false);
        setEvolving(true);
      }, REVEAL_DELAY + REVEAL_TIME);
    } catch (e) {
      console.error("Mint transaction failed:", e);
      updateNotification({
        message: `Transaction failed. ${e.message}`,
        title: "Error",
      });
    }
  };

  return (
    <>
      <ErrorWindow message={message} />
      <Container visible={visible}>
        <EvolvingEgg
          evolving={evolving}
          setEvolving={setEvolving}
          setNFTVisible={() => {
            setVisible(true);
            setMessageVisible(true);
          }}
        />
        {frogId ? (
          <FoundingFroggerNFT frogId={frogId} />
        ) : (
          <img
            alt="frog-egg"
            src={EGGS[accountToImageIndex(account)]}
            height={"100%"}
            width={"100%"}
          />
        )}
      </Container>

      <MessageHeader visible={messageVisible} nftMessage={Boolean(frogId)}>
        {frogId ? "Your Founding Frogger NFT 🎉" : "Congratulations!"}
      </MessageHeader>
      {frogId && <FrogData visible={messageVisible} frogId={frogId} />}

      {!frogId && (
        <>
          <Message visible={messageVisible && !countdownOver}>
            Your frog egg will hatch in {daysLeft} days, {hoursLeft} hours, {minutesLeft} minutes
            and {secondsLeft} seconds
          </Message>
          <ExtensionMessageContainer visible={messageVisible && !countdownOver} color="#f88702">
            <AiFillInfoCircle fontSize="20px" />

            <p>
              Hatching countdown extended by 180 minutes (to 3pm UTC) due to adverse weather
              conditions
            </p>
          </ExtensionMessageContainer>
          {mintChecksPassed && (
            <BeneficiaryContainer visible={messageVisible && countdownOver}>
              <p>
                Please enter the Ethereum address to which you want to mint this Founding Frogger
              </p>
              <BeneficiaryInput
                value={beneficiary}
                onChange={(e) => setBeneficiary(e.target.value)}
              />
              {beneficiaryError && (
                <BeneficiaryError>Not a valid Ethereum address</BeneficiaryError>
              )}
            </BeneficiaryContainer>
          )}

          <BottomContainer visible={messageVisible && countdownOver}>
            <ButtonContainer visible={messageVisible && countdownOver}>
              <ClaimButton
                onClick={() => {
                  onMintClick();
                }}
                glow={mintChecksPassed && ethers.utils.isAddress(beneficiary)}
              >
                Mint your Gyro Frog NFT
              </ClaimButton>
            </ButtonContainer>

            <MintInfo
              infoModalOpen={infoModalOpen}
              setInfoModalOpen={setInfoModalOpen}
              setMintChecksPassed={setMintChecksPassed}
            />
          </BottomContainer>
        </>
      )}
    </>
  );
};

const Container = styled.div<{ visible: boolean }>`
  align-items: center;
  display: flex;
  height: ${({ visible }) => (visible ? "500px" : "0")};
  justify-content: center;
  transition: all ${REVEAL_TIME / 1000}s ease-in-out;
  width: ${({ visible }) => (visible ? "500px" : "0")};

  img {
    animation: ${({ visible }) =>
      visible
        ? `breathe ${ANIMATION_TIME / 1000}s ease-in-out ${
            (REVEAL_DELAY + REVEAL_TIME) / 1000
          }s infinite`
        : "evolve ease-in-out 1s"};

    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity ${REVEAL_TIME / 1000}s ease-in-out;

    @keyframes breathe {
      0% {
        height: 500px;
        width: 500px;
      }

      50% {
        height: 475px;
        width: 475px;
      }

      100% {
        height: 500px;
        width: 500px;
      }
    }

    @keyframes evolve {
      100% {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

const MessageHeader = styled.h2<{ visible: boolean; nftMessage: boolean }>`
  font-weight: 900;
  font-size: 42px;
  margin-top: ${({ nftMessage }) => (nftMessage ? "50px" : "")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all ${REVEAL_TIME / 1000}s ease-in-out;
`;

export const Message = styled.p<{ visible: boolean; color?: string }>`
  color: ${({ color }) => (color ? color : "")};
  font-family: monospace;
  font-size: 21px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 0 20px;
  transition: all ${REVEAL_TIME / 1000}s ease-in-out;
`;

const BottomContainer = styled.div<{ visible: boolean }>`
  align-items: center;
  display: flex;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${REVEAL_TIME / 1000}s ease-in-out;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const ButtonContainer = styled.div<{ visible: boolean }>`
  cursor: ${({ visible }) => (visible ? "pointer" : "none")};
`;

const BeneficiaryContainer = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${REVEAL_TIME / 1000}s ease-in-out;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  p {
    font-size: 16px;
  }
`;

const BeneficiaryInput = styled.input`
  background: black;
  color: white;
  font-family: monospace;
  height: 20px;

  &:focus {
    outline: none;
  }
`;

const BeneficiaryError = styled.p`
  color: red;
`;

const ExtensionMessageContainer = styled.div<{ visible: boolean; color: string }>`
  align-items: center;
  display: flex;
  color: ${({ color }) => (color ? color : "")};
  font-family: monospace;
  font-size: 21px;
  height: ${({ visible }) => (visible ? "32px" : "0px")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 0 20px;
  transition: all ${REVEAL_TIME / 1000}s ease-in-out;
  svg {
    margin: 10px;
  }
`;

function accountToImageIndex(account) {
  return parseInt(account.slice(account.length - 6, account.length), 16) % 10;
}

export default Frog;
