import styled from "styled-components";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Window, WindowHeader, Button, Checkbox } from "react95";
import { CloseIcon } from "components/react95";
import { AiFillWarning } from "react-icons/ai";
import { Link } from "react-router-dom";

export const MintInfo = ({
  infoModalOpen,
  setInfoModalOpen,
  setMintChecksPassed,
}: {
  infoModalOpen: boolean;
  setInfoModalOpen: Dispatch<SetStateAction<boolean>>;
  setMintChecksPassed: Dispatch<SetStateAction<boolean>>;
}) => {
  const [termsReadCheck, setTermsReadCheck] = useState(false);
  const [photoSensitivityCheck, setPhotoSensitivityCheck] = useState(false);

  useEffect(() => {
    if (!(termsReadCheck && photoSensitivityCheck)) setMintChecksPassed(false);
  }, [termsReadCheck, photoSensitivityCheck, setMintChecksPassed]);

  return (
    <>
      <AiFillInfoCircle
        onClick={() => setInfoModalOpen(true)}
        style={{ marginLeft: "15px", color: "#ff9900", cursor: "pointer" }}
      />
      {infoModalOpen && (
        <ModalContainer>
          <Window onClick={(e) => e.stopPropagation()}>
            <StyledWindowHeader>
              <span>Important information</span>
              <Button onClick={() => setInfoModalOpen(false)}>
                <CloseIcon />
              </Button>
            </StyledWindowHeader>
            <CenterContainer>
              <AiFillWarning fontSize={"40px"} />
            </CenterContainer>

            <ContentContainer>
              <p>
                You have two options available to mint the Gyroscope DAO NFT:
                <ul>
                  <li>Option 1: Continue the process on this website (see below); </li>
                  <li>
                    Option 2: Use a tool hosted on IPFS and interact with the smart contract via
                    Etherscan;
                  </li>
                </ul>
              </p>

              <p>
                FTL Labs has made a tool available on IPFS that checks whether an address is
                eligible for an NFT against a merkle tree. If eligible, information from the merkle
                proof can be used to mint the Gyroscope DAO NFT on Ethereum. This route is more
                decentralized: the user directly interacts with the contract on Etherscan, and the
                code for the IPFS hosted tool is fully open-sourced and pinned via Fleek.
              </p>
              <p>
                More information can be found{" "}
                <a
                  href="https://gov.gyro.finance/t/decentralized-access-to-the-gyroscope-protocol/12511"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </p>
              <br />
              <p>TO CONTINUE WITH OPTION 1 READ BELOW</p>
              <p>
                Please read and ensure you understand and accept the terms of service. The terms of
                service can be found <Link to="/terms-of-service">here</Link>.
              </p>

              <p>
                The following frog egg evolution animation contains rapid flashing patterns. If you
                are sensitive to flashing patterns please do not pursue this option, or
                alternatively look away for one minute while the animation completes.
              </p>
              <p>
                If you have already minted your NFT, you will not be able to mint it again. Please
                double-check any addresses you enter as you will not be able to change them later.
                Please view the user interface from the address which owns the NFT to see the
                Founding Frogger.
              </p>
            </ContentContainer>
            <ContentContainer>
              <RightContainer>
                <p>I understand and accept the terms of service</p>
                <Checkbox
                  checked={termsReadCheck}
                  onClick={() => setTermsReadCheck((prev) => !prev)}
                />
              </RightContainer>
              <RightContainer>
                <p>
                  I understand that the frog egg evolution animation contains rapidly flashing
                  patterns
                </p>
                <Checkbox
                  checked={photoSensitivityCheck}
                  onClick={() => setPhotoSensitivityCheck((prev) => !prev)}
                />
              </RightContainer>
            </ContentContainer>
            <CenterContainer>
              <Button
                disabled={!(termsReadCheck && photoSensitivityCheck)}
                onClick={() => {
                  setMintChecksPassed(true);
                  setInfoModalOpen(false);
                }}
              >
                Continue
              </Button>
            </CenterContainer>
          </Window>
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  max-width: 1000px;
  padding: 20px 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
  text-align: left;
`;

const RightContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  text-align: right;
`;

const ContentContainer = styled.div`
padding 0 30px 20px;  
a {
    text-decoration: underline;
  }

  p {
    margin: 10px 5px;
  }
`;
