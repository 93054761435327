import { ethers } from "ethers";
import frogMinterABI from "../../../static/frogMinterABI.json";
import frogsData from "../../../static/frogs.json";
import { FROG_MINTER_ADDRESS } from "../../../static/deployment";
import { checkInvalidIP } from "../../../App";

// FROG CLASS -----------------------------------------------------

class Frog {
  constructor(readonly account: string, readonly id: number, readonly imageHash: string) {}

  static fromObject(obj: { account: string; id: number; imageHash: string }): Frog {
    return new Frog(obj.account, obj.id, obj.imageHash);
  }

  hash(): string {
    return ethers.utils.solidityKeccak256(
      ["address", "uint256", "bytes32"],
      [this.account, this.id, this.imageHash]
    );
  }
}

const frogs = frogsData.map(({ account, id, imageHash }) => new Frog(account, id, imageHash));

// -----------------------------------------------------------------

export const mintFrog = async (account, provider, setFrogId, beneficiary) => {
  if (await checkInvalidIP()) throw new Error("Gyroscope is not available in your region");

  const currentProvider =
    provider || (window.ethereum && new ethers.providers.Web3Provider(window.ethereum));

  const frog = frogs.find((frog) => frog.account.toLowerCase() === account.toLowerCase());

  const proof = generateProof(account, frogs);
  const frogMeta = { beneficiary, tokenId: frog.id, imageHash: frog.imageHash };
  const signer = currentProvider.getSigner(account);
  const minter = new ethers.Contract(FROG_MINTER_ADDRESS, frogMinterABI, signer);

  const tx = await minter.mint(frogMeta, proof);
  await tx.wait();

  setTimeout(() => setFrogId(frog.id), 16000);
};

// GENERATE PROOF -----------------------------------------------

type Proof = string[];

function generateProof(account: string, frogs: Frog[]): Proof {
  const leafIndex = frogs.findIndex(
    (frog) => ethers.utils.getAddress(frog.account) === ethers.utils.getAddress(account)
  );
  if (leafIndex === -1) {
    throw new Error("Account not found on NFT eligible list");
  }
  let nodeIndex = leafIndex;
  const hashes: string[] = [];

  const process = (leaves: string[]) => {
    const delta = nodeIndex % 2 === 0 ? 1 : -1;
    hashes.push(leaves[nodeIndex + delta]);
    nodeIndex = Math.floor(nodeIndex / 2);
  };

  traverseMerkle(frogs, process);
  return hashes;
}

function traverseMerkle(
  frogs: Frog[],
  process: ((leaves: string[]) => void) | undefined = undefined
): string {
  let leaves = frogs.map((frog) => frog.hash());
  while (leaves.length > 1) {
    if (leaves.length % 2 === 1) {
      leaves.push(ethers.utils.solidityKeccak256([], []));
    }
    if (process) process(leaves);

    const newLeaves = [];
    for (let i = 0; i < leaves.length; i += 2) {
      let [left, right] = [leaves[i], leaves[i + 1]];
      if (left > right) [left, right] = [right, left];
      newLeaves.push(ethers.utils.solidityKeccak256(["bytes32", "bytes32"], [left, right]));
    }
    leaves = newLeaves;
  }
  return leaves[0];
}
