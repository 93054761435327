import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";

const EXTENSION_TIME = 10800000; // 3 hrs

function calculateTime(
  divisor: number,
  modulus: number,
  launchTime: number,
  setter?: Dispatch<SetStateAction<string>>
) {
  let timeLeft = Math.max(
    Math.floor(((launchTime - Date.now()) / (divisor * 1000)) % modulus),
    0
  ).toString();

  setter && setter(String(timeLeft).length === 1 ? "0" + timeLeft : timeLeft);

  return timeLeft;
}

function count(launchTime, setSecondsLeft, setMinutesLeft, setHoursLeft, setDaysLeft) {
  calculateTime(1, 60, launchTime, setSecondsLeft);
  calculateTime(60, 60, launchTime, setMinutesLeft);
  calculateTime(3600, 24, launchTime, setHoursLeft);
  calculateTime(86400, 1000000000000, launchTime, setDaysLeft);
}

export const useCountdown = (initialLaunchTime: number) => {
  const launchTime = useRef(initialLaunchTime);

  const [secondsLeft, setSecondsLeft] = useState(calculateTime(1, 60, launchTime.current));
  const [minutesLeft, setMinutesLeft] = useState(calculateTime(60, 60, launchTime.current));
  const [hoursLeft, setHoursLeft] = useState(calculateTime(3600, 24, launchTime.current));
  const [daysLeft, setDaysLeft] = useState(calculateTime(86400, 1000000000000, launchTime.current));
  const [countdownOver, setCountdownOver] = useState(false);

  // useEffect
  useEffect(() => {
    (async () => {
      if (Date.now() > launchTime.current) {
        launchTime.current += EXTENSION_TIME;
      } else {
        await delay(5000);
        for (let i = 0; i < EXTENSION_TIME; i += 10000) {
          launchTime.current += 10000;
          count(launchTime.current, setSecondsLeft, setMinutesLeft, setHoursLeft, setDaysLeft);
          await delay(1);
        }
      }

      setInterval(() => {
        count(launchTime.current, setSecondsLeft, setMinutesLeft, setHoursLeft, setDaysLeft);
        if (launchTime.current <= Date.now()) setCountdownOver(true);
      }, 1000);
    })();

    return clearInterval;
  }, []);

  return {
    secondsLeft,
    minutesLeft,
    hoursLeft,
    daysLeft,
    countdownOver,
  };
};

function delay(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}
