import { useState, useCallback } from "react";
import { ethers } from "ethers";
import { useWeb3Modal } from "contexts/Web3";
import Context, { eligibilityStatusType, claimStatusType } from "./Context";
import frogs from "../../static/frogs.json";
import foundingFrogABI from "../../static/foundingFrogABI.json";
import { FOUNDING_FROG_ADDRESS } from "static/deployment";

const PROGRESS_BAR_LOAD_TIME = 10000;

const Provider = ({ children }) => {
  const [eligibilityStatus, setEligbilityStatus] =
    useState<eligibilityStatusType>("awaiting eligibility");
  const [claimStatus, setClaimStatus] = useState<claimStatusType>("unclaimed");
  const [claimProgress, setClaimProgress] = useState(0);
  const [frogId, setFrogId] = useState<number | null>(null);

  const { account, provider } = useWeb3Modal();

  const findNFTStatus = useCallback(async () => {
    const frogId = await findFrogId(account, provider);
    if (frogId) {
      setFrogId(frogId);
      return setClaimStatus("claimed");
    }

    // Check cache
    if (localStorage.getItem(`nft-eligibility-${account.toLowerCase()}`) === "eligible")
      return setEligbilityStatus("eligible");
    if (localStorage.getItem(`nft-eligibility-${account.toLowerCase()}`) === "not eligible")
      return setEligbilityStatus("not eligible");
    if (localStorage.getItem(`nft-eligibility-${account.toLowerCase()}`) === "claimed")
      return setClaimStatus("claimed");

    setEligbilityStatus("awaiting eligibility");

    setTimeout(() => {
      if (frogs.map(({ account }) => account.toLowerCase()).includes(account.toLowerCase())) {
        setEligbilityStatus("eligible");
        setClaimStatus("unclaimed");
        localStorage.setItem(`nft-eligibility-${account.toLowerCase()}`, "eligible");
      } else {
        setEligbilityStatus("not eligible");
      }
    }, 3000);
  }, [account, provider]);

  const resetNFTStatus = useCallback(() => {
    setClaimStatus("unclaimed");
    setClaimProgress(0);
    setEligbilityStatus("awaiting eligibility");
  }, []);

  async function claimNFTEgg(account: string) {
    setClaimStatus("claiming");

    const randomSampleSize = Math.ceil(Math.random() * 5) + 5;

    const randomProgress = Array.from(Array(randomSampleSize))
      .map(() => Math.floor(Math.random() * 100))
      .sort((a, b) => (a < b ? -1 : 1));

    randomProgress.push(100);

    const randomTimes = Array.from(Array(randomSampleSize + 1))
      .map(() => Math.floor(Math.random() * PROGRESS_BAR_LOAD_TIME))
      .sort((a, b) => (a < b ? -1 : 1));

    randomTimes.forEach((time, index) => {
      setTimeout(() => {
        setClaimProgress(randomProgress[index]);
      }, time);
    });

    setTimeout(() => {
      setClaimStatus("claimed");
      localStorage.setItem(`nft-eligibility-${account.toLowerCase()}`, "claimed");
    }, PROGRESS_BAR_LOAD_TIME);
  }

  return (
    <Context.Provider
      value={{
        eligibilityStatus,
        claimStatus,
        findNFTStatus,
        claimNFTEgg,
        claimProgress,
        resetNFTStatus,
        frogId,
        setFrogId,
      }}
    >
      {children}
    </Context.Provider>
  );
};

async function findFrogId(account, provider) {
  if (provider) {
    try {
      const foundingFrog = new ethers.Contract(FOUNDING_FROG_ADDRESS, foundingFrogABI, provider);
      const balance = await foundingFrog.balanceOf(account);
      if (balance.toNumber() > 0) {
        const frogIdBN = await foundingFrog.tokenOfOwnerByIndex(account, 0);
        return frogIdBN.toNumber();
      }
    } catch (e) {
      console.error("Unable to retrieve frogId");
    }
  }

  return null;
}

export default Provider;
