import styled from "styled-components";
import { useState, useEffect } from "react";
import { Message } from "./Frog";

type FrogAttributes = {
  Character: string;
  Environment: string;
  "Frog type": string;
  Rarity: string;
};

export const FoundingFroggerNFT = ({ frogId }: { frogId: number | null }) => {
  const [imageURL, setImageURL] = useState("");
  useEffect(() => {
    (async () => {
      if (frogId) {
        try {
          const res = await fetch(`https://founders.gyro.finance/metadata/${frogId}.json`);
          const json = await res.json();
          setImageURL(json.image);
        } catch (e) {
          console.error("Unable to find image URL of NFT", e);
        }
      }
    })();
  }, [frogId]);

  return (
    <Image
      alt="founding-frogger-nft"
      src={imageURL}
      height={"100%"}
      width={"100%"}
      visible={Boolean(imageURL)}
    />
  );
};

const Image = styled.img<{ visible: boolean }>`
  box-shadow: 0px 0px 50px 10px #fff;
  border: 2px solid #fff;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out;
`;

export const FrogData = ({ visible, frogId }: { visible: boolean; frogId: number }) => {
  const [attributes, setAttributes] = useState<FrogAttributes>(null);

  useEffect(() => {
    (async () => {
      if (frogId) {
        try {
          const res = await fetch(`https://founders.gyro.finance/metadata/${frogId}.json`);
          const json = await res.json();
          setAttributes(json.attributes);
        } catch (e) {
          console.error("Unable to find attributes of NFT", e);
        }
      }
    })();
  }, [frogId]);

  return attributes ? (
    <>
      {Object.entries(attributes).map(([key, value], index) => (
        <Message key={index} visible={visible}>
          {key}: {value}
        </Message>
      ))}
    </>
  ) : null;
};
